// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/goncalo.silvadias/gsdias/casamento-react/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ajuda-js": () => import("/Users/goncalo.silvadias/gsdias/casamento-react/src/pages/ajuda.js" /* webpackChunkName: "component---src-pages-ajuda-js" */),
  "component---src-pages-convidados-js": () => import("/Users/goncalo.silvadias/gsdias/casamento-react/src/pages/convidados.js" /* webpackChunkName: "component---src-pages-convidados-js" */),
  "component---src-pages-curiosidades-js": () => import("/Users/goncalo.silvadias/gsdias/casamento-react/src/pages/curiosidades.js" /* webpackChunkName: "component---src-pages-curiosidades-js" */),
  "component---src-pages-index-js": () => import("/Users/goncalo.silvadias/gsdias/casamento-react/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-informacoes-js": () => import("/Users/goncalo.silvadias/gsdias/casamento-react/src/pages/informacoes.js" /* webpackChunkName: "component---src-pages-informacoes-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/goncalo.silvadias/gsdias/casamento-react/.cache/data.json")

